.news {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid transparent;
  cursor: pointer;
}

.news:hover {
  border: 2px solid #376aed;
}

.news.news--active {
  border: 2px solid #376aed;
}

.news.news--deleted {
  opacity: 0.5;
}

.news + .news {
  margin-top: 12px;
}

.news--thumb {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 130px;
}

.news--language {
  background-color: #000;
  position: absolute;
  right: 4px;
  bottom: 4px;
  border-radius: 4px;
  padding: 4px;
  color: #fff;
  font-size: 12px;
}

.news--meta {
  display: inline-flex;
  margin-bottom: 4px;
  font-size: 14px;
  color: #838080;
}

.news--title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 0;
}

.news--content {
  flex: 1;
  padding: 0 12px;
}
