.container--page {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 16px;
}

.formUserReport {
  padding: 24px 0 16px 0 !important;
}

.formUserReport--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.formUserReport--result {
  display: inline-block;
}

.birthYear {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.birthYear--separator {
  width: 20px;
  display: flex;
  align-items: center;
}
