.newsList--container {
  flex: 1;
  max-width: 615px;
  height: 100%;
  margin: 0 24px;
  padding: 0 12px;
  overflow-y: auto;
  background-color: #f5f5f5;
}

.newsList--container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.newsList--container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
