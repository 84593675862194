.menu--item {
  display: flex;
  align-items: center;
  padding: 15px 12px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid transparent;
  text-decoration: none;
  color: #000;
}

.menu--item:hover {
  background-color: #f5f5f5;
  color: #000;
  border: 2px solid #f5f5f5;
}

.menu--item.menu--itemActive {
  border: 2px solid #376aed;
}

.menu--item.menu--itemDestac {
  padding: 18px 12px;
  background-color: #376aed;
  color: #fff;
}

.menu--item.menu--itemDestac:hover {
  background-color: #22449b;
  transition: all 0.3s ease-out;
}

.menu--item.menu--itemDestac span {
  color: #fff;
}

.menu--item + .menu--item {
  margin-top: 2px;
}

.menu--item svg {
  margin-right: 8px;
  font-size: 19px;
}

.menu--item span {
  font-weight: 700;
  color: #000;
}
