.previewApp {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid #d9d9d9;
}

.previewApp--thumb {
  margin-right: 8px;
  width: 100px;
  height: 130px;
  background-color: #dce0e6;
  background-size: cover;
  background-repeat: no-repeat;
}

.previewApp--content {
  flex: 1;
  padding-right: 4px;
}

.previewApp--content span {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #838080;
}

.previewApp--content h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
