.bg--main {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background: url('../../assets/appnews-splash-2.jpg') no-repeat center center;
  background-size: cover;
}

.bg--main::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);
}

.error--description {
  text-align: center;
}

.error--description h1,
.error--description p {
  position: relative;
  margin-bottom: 24px;
  color: #fff;
}

.error--description h1 {
  font-size: 150px;
  font-weight: 700;
}
.error--description p {
  font-size: 18px;
}
