@import '~antd/dist/antd.css';

/* reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

/* tags */
a:hover {
  transition: all 0.3s ease-out;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0;
}

/* typografy */
html {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

body {
  line-height: 1;
}

/* general */
.container {
  padding: 12px;
  margin: 0 auto;
  max-width: 1360px;
  display: flex;
  align-items: flex-start;
  height: 100vh;
}

.anime--left {
  opacity: 0;
  transform: translateX(-20px);
  animation: anime--left 0.3s forwards;
}

@keyframes anime--left {
  to {
    opacity: 1;
    transform: initial;
  }
}

.message--error {
  font-size: 12px;
  color: #e22134;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
