.container--addNews {
  display: flex;
  background-color: #f5f5f5;
  padding: 16px 0;
}

/* Form add news */
.container--form {
  width: 70%;
  padding: 0 16px;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  min-height: 450px;
  margin-bottom: 0 !important;
}

.wrapperContent {
  position: relative;
}

.contentCounter {
  position: absolute;
  left: 50%;
  bottom: 2px;
  z-index: 1;
  transform: translateX(-50%);
  color: #d9d9d9;
}

.buttons--options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.buttons--options > button {
  width: 100%;
}

.contentButton {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentButton {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons--options > button + button {
  margin-left: 16px;
}

.buttons--submit {
  display: flex;
  justify-content: space-between;
}

.submit--actions {
  display: flex;
  justify-content: flex-end;
}

.submit--actions > button + button {
  margin-left: 16px;
}

/* Preview */
.container--addPreview {
  width: 30%;
  height: calc(100vh - 32px);
  overflow-y: auto;
  background-color: #f5f5f5;
  margin-right: 16px;
}

.container--addPreview::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.container--addPreview::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
