.login--wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  gap: 32px;
}

.login--wrapper::before {
  display: block;
  content: "";
  background: url("../../assets/appnews-splash.jpg") no-repeat center center;
  background-size: cover;
}

.login--form {
  margin-top: 25vh;
  padding: 32px;
  max-width: 440px;
}

@media (max-width: 640px) {
  .login--wrapper {
    grid-template-columns: 1fr;
  }

  .login--wrapper::before {
    display: none;
  }

  .login--form {
    max-width: 100%;
  }
}

.login--version,
.login--error {
  text-align: center;
}

.login--version p,
.login--error p {
  font-size: 12px;
}

.login--version p span {
  font-weight: 700;
}

.login--error {
  color: #e22134;
}
