.latestImages {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.latestImages--item {
  width: 16.666%;
  padding: 6px;
}

.latestImages--thumb {
  height: 116px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  border: 4px solid transparent;
}

.latestImages--thumb:hover {
  border: 4px solid #376aed;
}

.latestImages--destac {
  border: 4px solid #376aed;
}

.latestImages--wrapperButtons {
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
}

.latestImages--wrapperButtons > button:first-child {
  margin-right: 16px;
}
