.sidebar {
  max-width: 235px;
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.menu--container {
  background-color: #f5f5f5;
  padding: 2px;
}

.menu--container + .menu--container {
  margin-top: 24px;
}
