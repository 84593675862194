.formImage {
  min-height: 400px;
}

.fileUpload--input {
  display: none;
}

.fileUpload--label {
  display: inline-block;
  height: 32px;
  padding: 4px 15px;

  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 2px;

  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5715;
  white-space: nowrap;

  cursor: pointer;
  touch-action: manipulation;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fileUpload--label:hover {
  background: #fff;
  color: #40a9ff;
  border-color: #40a9ff;
}

.imagePlaceholder--wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  background-color: #f5f5f5;
}

.iconUpload {
  margin-bottom: 4px;
  font-size: 30px;
}

.imageCropper {
  width: 100%;
  height: 400px;
  margin-bottom: 16px;
}

.description--wrapper {
  margin-bottom: 16px;
}

.imageButton--wrapper {
  display: flex;
  justify-content: flex-end;
}

.imageButton--wrapper button:first-child {
  margin-right: 20px;
}
