/* preview */
.container-preview {
  flex: 1;
  max-width: 514px;
  height: 100%;
  overflow-y: auto;
  background-color: #f5f5f5;
}

.preview--options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  padding: 15px 12px;

  background-color: #4f505b;
  color: #fff;
}

.preview--edit svg {
  cursor: pointer;
  font-size: 20px;
}

.preview--edit a {
  color: #fff;
  display: inline-block;
}

.preview--edit svg:hover {
  color: #376aed;
  transition: all 0.3s ease-out;
}

.preview--edit svg + a {
  margin-left: 16px;
}

.preview--options p {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1.4;
}

.preview--content {
  padding: 16px 12px;
}

.preview--header span {
  color: #838080;
  display: inline-block;
  margin-bottom: 8px;
}

.preview--header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}

.preview--thumb figcaption {
  margin-top: 8px;
  font-size: 12px;
  line-height: 1.3;
  color: #838080;
}

.preview--thumbEmpty {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 24px;
  height: 340px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.preview--description p {
  font-size: 15px;
  line-height: 1.4;
  color: #000;
  margin-bottom: 18px;
}

.preview--containerLink {
  margin-bottom: 24px;
}

.preview--link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  font-weight: 400;
  font-size: 15px;
  margin: 24px 0 6px 0;
}

.preview--link:hover {
  color: #376aed;
}

.preview--footer {
  text-align: center;
  padding-bottom: 16px;
}

.preview--footer p {
  margin-bottom: 4px;
}

.preview--footer img {
  max-width: 204px;
  margin-right: auto;
  margin-left: auto;
}

.preview::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.preview::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.preview--pushdata {
  background-color: #4f505b;
  padding: 6px;
}

.preview--pushdata table {
  width: 100%;
}

.preview--pushdata th,
.preview--pushdata td {
  text-align: left;
  padding: 6px;
  color: white;
  text-align: center;
}
